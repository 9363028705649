import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
/**
 * @param  {} options={}
 * @param  {} startTime
 * @param  {} endTime
 * @param  {} salesPerson
 */
export default async function(options = {}) {
    var { startTime, endTime, splitAccountIds, stageFilter } = options;
    try {
        var config = fetchConfig.bind(this, options, false)();
        var url = config.baseUrl + `sale/persons/performance/summary?`;
        if (startTime) url += "&start_time=" + startTime;
        if (endTime) url += "&end_time=" + endTime;
        if (splitAccountIds) url += "&split_acc_ids=" + splitAccountIds;
        if (stageFilter) url += "&stage_filter=" + stageFilter;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200) throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
