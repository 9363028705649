import fetchConfig from '../../../helpers/fetchConfig';
import get from '../../../helpers/get';
export default async function (options = {}) {
    var {
        username,
        baseUrl
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + 'v2/user/exists?';
        if (!username)
            throw 'No username provided';
        url += 'username=' + encodeURIComponent(username);
        var results = await get(url, config);
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};