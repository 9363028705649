import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, baseUrl, accountId, specId, algoIssues
    } = options;
    try {
        var config = fetchConfig.bind(this, options, true)();
        var url = config.baseUrl + `vehicle/health/count?`;
        if (specId)
            url += "&spec_id=" + specId;
        if (algoIssues)
            url += "&algo_issues=" + algoIssues;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.count)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}