
import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        startTime, endTime, splitAccountIds
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + `performance/summary/export?`;

        if (startTime) url += "&start_time=" + startTime;
        if (endTime) url += "&end_time=" + endTime;
        if (splitAccountIds) url += "&split_acc_ids=" + splitAccountIds;
    
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};