import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, baseUrl,
        accountId, ids
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "saleproduct/getbyids?";

        if (Array.isArray(ids) && ids.length) {
            url += `ids=${ids.join(",")}`;
        }
        var results = await get(url, { token: config.token, accountId: accountId || config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}