import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        query,
        pnum,
        psize,
        hwConfig,
        auxHwConfig,
        controllerType,
        version,
        getDeleted,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/firmware/list?`;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;
        if(query)
            url += '&query=' + query;
            if(controllerType)
            url += '&controller_type=' + controllerType;  
            if(auxHwConfig)
            url += '&aux_hw_config=' + auxHwConfig;
            if(version)
            url += '&version=' + version;
            if(getDeleted)
            url += '&get_deleted=' + getDeleted;
            if(hwConfig)
            url += '&hw_config=' + hwConfig;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}