import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        fields,
        sale_id,
        status,
        pnum,
        psize,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `/sale/delivery/lot/list?`;
        fields ? url += '&fields=' + fields : null;
        sale_id ? url += '&sale_id=' + sale_id : null;
        status ? url += '&status=' + status : null;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}