import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, psize = 50, pnum = 1,
        baseUrl, accountId, getUserInfo,
        getArchived, entityId, entityType
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "note/list?";

        if (accountId)
            url += '&account_id=' + accountId;
        if (entityType)
            url += '&entity_type=' + entityType;
        if (entityId)
            url += '&entity_id=' + entityId;
        if (getUserInfo)
            url += '&get_user_info=true';
        if (!getArchived)
            url += '&states=1,2,3';
        else
            url += '&states=*';
        if (pnum)
            url += '&pnum=' + pnum;
        if (psize)
            url += '&psize=' + psize;
        else
            url += '&psize=3';

        var results = await get(url, { token: config.token, accountId: accountId || config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}