import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, psize = 50, pnum = 1,
        status, query, stage, baseUrl,
        accountId, lastLoc, obd, sorting,
        updatedAfter
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "vehicle/getlist?pnum=" + pnum + "&psize=" + psize;
        if (typeof query === 'object') {
            if (query.vid)
                url += "&vid=" + query.vid;
            if (query.query)
                url += "&query=" + query.query;
        } else if (typeof query === 'string') {
            url += "&query=" + query;
        }
        if (updatedAfter)
            url = url + "&updatedafter=" + updatedAfter
        if (lastLoc)
            url = url + "&lastloc=true&drivers=true";
        else
            url = url + "&lastloc=false";
        if (obd) {
            url = url + "&obd=true";
            url = url + "&sort=health desc";
        }
        if (sorting) {
            var sort = '';
            for (var i = 0; i < sorting.length; i++) {
                var sortType = sorting[i].isasc ? 'asc' : 'desc';
                sort += sorting[i].property + ' ' + sortType;
            }
            if (sort && sort !== '') {
                url += "&sort=" + sort;
            }
        } if (options) {
            if (options.fuel) {
                url += "&fuel=" + options.fuel;
            }
            if (options.msort) {
                var msort = '';
                for (var i = 0; i < options.msort.length; i++) {
                    var sortType = options.msort[i].isasc ? 'asc' : 'desc';
                    msort += options.msort[i].property + ' ' + sortType;
                }
                if (msort && msort !== '') {
                    url += "&msort=" + msort;
                }
            }
            if (options.healths)
                url += "&healths=" + options.healths;
            if (options.spec_ids)
                url += "&spec_ids=" + options.spec_ids;
            if (options.specs)
                url += "&specs=" + options.specs;
            if (options.adblue)
                url += "&adblue=" + options.adblue;
            if (options.moving)
                url += "&moving=" + options.moving;
            if (options.algos_only)
                url += "&algos_only=" + options.algos_only;
            if (options.obd_odo)
                url += "&obd_odo=true";
            if (options.algo_issues)
                url += "&algo_issues=" + options.algo_issues;
        }

        var results = await get(url, { token: config.token, accountId: accountId || config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}