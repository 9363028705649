import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        deviceType,
        controllerType,
        query,
        pnum,
        psize,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/mc/code/listV2?`;
        deviceType ? url += '&device_type=' + deviceType : null;
        controllerType ? url += '&controller_type=' + controllerType : null;
        query ? url += '&query=' + query : null;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}