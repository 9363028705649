import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function(options = {}) {
    var {
        noThrow,
        type,
        pnum,
        psize,
        name,
        currency,
        fields,
        query,
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `/inventory/supplier/list?`;
        
        if (!pnum) pnum = 1;
        if (!psize) psize = 20;
        if (type) url = url + "&type=" + type;
        if (fields) url = url + "&fields=" + fields;
        if (name) url = url + "&name=" + name;
        if(currency) url = url + "&currency=" + currency;
        if(query) url = url + "&query=" + query;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow) return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
