import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function(options = {}) {
    var {
        noThrow,
        query,
        pnum,
        psize,
        showall,
        getVehicleInfo,
        vehicleId,
        imeis,
        imei,
        accountIds,
        vids,
        vid,
        type,
        tv,
        l2,
        ov,
        stm,
        tags,
        manufacturingBatches,
        isAvailable,
        lotId,
        deployed,
        deviceModel,
        deviceVersion,
        auxBoard,
        statusReasons,
        hwconfigDefinition,
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/listV2?`;
        if (query && query != "*") url += "&query=" + query;
        if (showall) url += "&showall=true";
        if (getVehicleInfo) url += "&getvehicleInfo=true";
        if (vehicleId) url = url + "&vid=" + vehicleId;
        if (Array.isArray(imeis)) url = url + "&imeis=" + imeis.join(",");
        else if (imeis) url = url + "&imei=" + imeis;
        if (imei) url = url + "&imei=" + imei;
        if (!pnum) pnum = 1;
        if (!psize) psize = 20;
        if (Array.isArray(accountIds) && accountIds.length)
            url += "&acc_id=" + accountIds.join(",");
        else if (typeof accountIds === "string")
            url += "&acc_id=" + accountIds;

        if (Array.isArray(vids) && vids.length)
            url += "&vids=" + vids.join(",");
        else if (typeof vids === "string") url += "&vids=" + vids;
        if (type) url = url + "&type=" + type;

        if (vid) url = url + "&vid=" + vid;

        if(lotId) url = url + "&lot_id=" + lotId;

        if (Array.isArray(tv) && tv.length)
            url += "&tv=" + tv.join(",");
        else if (typeof tv === "string") url += "&tv=" + tv;

        if (Array.isArray(l2) && l2.length)
            url += "&l2=" + l2.join(",");
        else if (typeof l2 === "string") url += "&l2=" + l2;
        
        if (Array.isArray(ov) && ov.length)
            url += "&ov=" + ov.join(",");
        else if (typeof ov === "string") url += "&ov=" + ov;

        if (Array.isArray(stm) && stm.length)
            url += "&stm=" + stm.join(",");
        else if (typeof stm === "string") url += "&stm=" + stm;

        if (Array.isArray(statusReasons) && statusReasons.length)
            url += "&availability_status_reasons=" + statusReasons.join(",");
        else if (typeof statusReasons === "string") url += "&availability_status_reasons=" + statusReasons;

        if (Array.isArray(manufacturingBatches) && manufacturingBatches.length)
            url += "&manufacturing_batches=" + manufacturingBatches.join(",");
        else if (typeof manufacturingBatches === "string") url += "&manufacturing_batches=" + manufacturingBatches;

        if (Array.isArray(deviceModel) && deviceModel.length)
            url += "&device_model=" + deviceModel.join(",");
        else if (typeof deviceModel === "string") url += "&device_model=" + deviceModel;

        if (Array.isArray(deviceVersion) && deviceVersion.length)
            url += "&device_version=" + deviceVersion.join(",");
        else if (typeof deviceVersion === "string") url += "&device_version=" + deviceVersion;

        if (Array.isArray(auxBoard) && auxBoard.length)
            url += "&aux_board=" + auxBoard.join(",");
        else if (typeof auxBoard === "string") url += "&aux_board=" + auxBoard;

        if (Array.isArray(tags) && tags.length)
            url += "&aux_board=" + tags.join(",");
        else if (typeof tags === "string") url += "&aux_board=" + tags;

        if(deployed)
            url += '&deployed=true';
        
        if(isAvailable)
            url += '&is_available=true';
        if(hwconfigDefinition)
            url += '&hwconfig_definition=true';    

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow) return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
