import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        generation,
        version,
        query,
        pnum,
        psize,
        is_aux,model_name,model_id,
        ids,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/hwconfig/list?`;
        generation ? url += '&generation=' + generation : null;
        version ? url += '&version=' + version : null;
        query ? url += '&query=' + query : null;
        is_aux ? url += '&is_aux=' + is_aux : null;
        model_name ? url += '&model_name=' + model_name:null;
        model_id ? url += '&model_id=' +model_id:null;
        if (Array.isArray(ids)) url = url + "&ids=" + ids.join(",");
        else if (ids) url = url + "&ids=" + ids;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}