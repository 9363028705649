import getUserByToken from './getUserByToken.api';
import localstorage from '../../../helpers/localstorage';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (givenOptions = {}){
    try {
        var config = fetchConfig.bind(this, givenOptions)();
        var options = {
            token: await this.storage.getItem('UserToken') || config.token,
            baseUrl: config.baseUrl
        }
        if(!options.token)
            throw new Error("No token found");
        return {
            valid: true,
            result: await getUserByToken.bind(this,options)()
        }
    }
    catch(e) {
        if(e && e.status)
            return {
                valid: false,
                error: e
            }
        else
            return {
                valid: null,
                error: (e && e.message) || "Couldn't fetch" 
            }
    }
}