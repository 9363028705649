import get from "../../../helpers/get";
import fetchConfig from '../../../helpers/fetchConfig';
export default async function fetchAlertCounts(options = {}) {
    var { token, accountId, psize = 20, pnum = 1, vehicles, from, to, baseUrl} = options;
    try{
        var config = fetchConfig.bind(this,options,true)();
        var url = config.baseUrl + `alertlog/logsV2/${from}/${to}/count?`;
        var types = [ 
            'over_speed', 'speeding', 'idling', 'stoppage', 'hard_brake', 'freerun'
        ];
        if(psize)
            url += "&psize="+psize;
        else
            url += "&psize="+20;
        if(pnum)
            url += "&pnum="+pnum;
        else
            url += "&pnum="+1;
        if(types)
            url += "&types="+types.join(',');
        if(vehicles && vehicles.length > 0)
            url +=(vehicles instanceof Array) ? "&vehicles="+vehicles.join(','): "&vehicles="+vehicles;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if(!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
    
}