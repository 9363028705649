import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function(options = {}) {
    var { generation, version } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != "/") config.baseUrl += "/";
        var url = config.baseUrl + `idevice/hwconfig/is_available?`;
        if(generation)
            url += '&generation='+generation;
        if(version)
            url += '&version='+version;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200) throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
