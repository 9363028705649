import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var { 
        token, baseUrl, accountId, noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this,options,true)();
        var url = config.baseUrl + `account/${config.accountId}/specsV2?`;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if(noThrow)
            return results;
        if(!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}