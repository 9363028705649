import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize,
        pnum,
        specs,
        manufacturer,
        account_id,
        query,
        model
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + "specs/listV2?";
        psize ? url += `&psize={psize}` : null;
        pnum ? url += `&pnum={pnum}` : null;
        specs ? url += `&specs={specs}` : null;
        manufacturer ? url += `&manufacturer={manufacturer}` : null;
        account_id ? url += `&account_id={account_id}` : null;
        query ? url += `&query={query}` : null;
        model ? url += `&model={model}` : null;

        var results = await post(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};