if(!fetch) require('isomorphic-fetch');
export default async (url,data,{token,accountId}={}) => {
    if(token)
        url += "&token="+token;
    if(accountId)
        url += "&acc_id="+accountId;
    var response = await fetch(url,{
        method: "PUT",
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        body:data
    });
    // var results = await response.json();
    return response;
}