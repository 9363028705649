import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        deprecated,
        latest,
        query,
        pnum,
        psize,
        imei,
        controller,
        noThrow,
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/${imei}/${controller}/supporting_firmware?`;
        deprecated ? url += '&deprecated=' + deprecated : null;
        latest ? url += '&latest=' + latest : null;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}