let getItem = async (key) => {
    return await window.localStorage.getItem(key);
}
let removeItem = async (key) => {
    return await window.localStorage.removeItem(key);
}
let setItem = async (key,value) => {
    return await window.localStorage.setItem(key,value);
}
let clear = async () => {
    return await window.localStorage.clear();
}
export default {
    ...window.localStorage,
    getItem,
    setItem,
    removeItem,
    clear
}