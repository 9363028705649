import localstorage from './helpers/localstorage';
export default class{
    constructor(config) {
        this.storage = localstorage;
        this.initialize(config);
    }
  	initialize(config) {
        this.setConfig(config);
    	this.Vehicle= {
        getVehicleList: require('./classes/Vehicle/apis/getVehicleList.api.js').default.bind(this) || require('./classes/Vehicle/apis/getVehicleList.api.js').bind(this),
        getVehicleInfo: require('./classes/Vehicle/apis/getVehicleInfo.api.js').default.bind(this) || require('./classes/Vehicle/apis/getVehicleInfo.api.js').bind(this),
        getHealthAlgoResults: require('./classes/Vehicle/apis/getHealthAlgoResults.api.js').default.bind(this) || require('./classes/Vehicle/apis/getHealthAlgoResults.api.js').bind(this),
        getVehicleCountBasedOnHealth: require('./classes/Vehicle/apis/getVehicleCountBasedOnHealth.api.js').default.bind(this) || require('./classes/Vehicle/apis/getVehicleCountBasedOnHealth.api.js').bind(this),
        attachSpecification: require('./classes/Vehicle/apis/attachSpecification.api.js').default.bind(this) || require('./classes/Vehicle/apis/attachSpecification.api.js').bind(this),
        getLocationHistory: require('./classes/Vehicle/apis/getLocationHistory.api.js').default.bind(this) || require('./classes/Vehicle/apis/getLocationHistory.api.js').bind(this),
}
this.User= {
        login: require('./classes/User/apis/login.api.js').default.bind(this) || require('./classes/User/apis/login.api.js').bind(this),
        loginWithOtp: require('./classes/User/apis/loginWithOtp.api.js').default.bind(this) || require('./classes/User/apis/loginWithOtp.api.js').bind(this),
        logout: require('./classes/User/apis/logout.api.js').default.bind(this) || require('./classes/User/apis/logout.api.js').bind(this),
        getUserByToken: require('./classes/User/apis/getUserByToken.api.js').default.bind(this) || require('./classes/User/apis/getUserByToken.api.js').bind(this),
        authenticate: require('./classes/User/apis/authenticate.api.js').default.bind(this) || require('./classes/User/apis/authenticate.api.js').bind(this),
        getNotifications: require('./classes/User/apis/getNotifications.api.js').default.bind(this) || require('./classes/User/apis/getNotifications.api.js').bind(this),
        addChildUser: require('./classes/User/apis/addChildUser.api.js').default.bind(this) || require('./classes/User/apis/addChildUser.api.js').bind(this),
        verifyOtp: require('./classes/User/apis/verifyOtp.api.js').default.bind(this) || require('./classes/User/apis/verifyOtp.api.js').bind(this),
        requestOtp: require('./classes/User/apis/requestOtp.api.js').default.bind(this) || require('./classes/User/apis/requestOtp.api.js').bind(this),
        update: require('./classes/User/apis/update.api.js').default.bind(this) || require('./classes/User/apis/update.api.js').bind(this),
        checkIfExists: require('./classes/User/apis/checkIfExists.api.js').default.bind(this) || require('./classes/User/apis/checkIfExists.api.js').bind(this),
        getUserList: require('./classes/User/apis/getUserList.api.js').default.bind(this) || require('./classes/User/apis/getUserList.api.js').bind(this),
}
this.Account= {
        addAccount: require('./classes/Account/apis/addAccount.api.js').default.bind(this) || require('./classes/Account/apis/addAccount.api.js').bind(this),
        updateAccount: require('./classes/Account/apis/updateAccount.api.js').default.bind(this) || require('./classes/Account/apis/updateAccount.api.js').bind(this),
        getAccounts: require('./classes/Account/apis/getAccounts.api.js').default.bind(this) || require('./classes/Account/apis/getAccounts.api.js').bind(this),
        getAccountInfo: require('./classes/Account/apis/getAccountInfo.api.js').default.bind(this) || require('./classes/Account/apis/getAccountInfo.api.js').bind(this),
        getAllVehicles: require('./classes/Account/apis/getAllVehicles.api.js').default.bind(this) || require('./classes/Account/apis/getAllVehicles.api.js').bind(this),
        getSpecs: require('./classes/Account/apis/getSpecs.api.js').default.bind(this) || require('./classes/Account/apis/getSpecs.api.js').bind(this),
        getVehicleCountBasedOnHealth: require('./classes/Account/apis/getVehicleCountBasedOnHealth.api.js').default.bind(this) || require('./classes/Account/apis/getVehicleCountBasedOnHealth.api.js').bind(this),
        updateChildren: require('./classes/Account/apis/updateChildren.api.js').default.bind(this) || require('./classes/Account/apis/updateChildren.api.js').bind(this),
        getAggregatedCounts: require('./classes/Account/apis/getAggregatedCounts.api.js').default.bind(this) || require('./classes/Account/apis/getAggregatedCounts.api.js').bind(this),
}
this.Dtc= {
        getDtcInfos: require('./classes/Dtc/apis/getDtcInfos.api.js').default.bind(this) || require('./classes/Dtc/apis/getDtcInfos.api.js').bind(this),
}
this.EOL= {
        attachDevice: require('./classes/EOL/apis/attachDevice.api.js').default.bind(this) || require('./classes/EOL/apis/attachDevice.api.js').bind(this),
        getReportByImei: require('./classes/EOL/apis/getReportByImei.api.js').default.bind(this) || require('./classes/EOL/apis/getReportByImei.api.js').bind(this),
        startTest: require('./classes/EOL/apis/startTest.api.js').default.bind(this) || require('./classes/EOL/apis/startTest.api.js').bind(this),
}
this.Sale= {
        registerSale: require('./classes/Sale/apis/registerSale.api.js').default.bind(this) || require('./classes/Sale/apis/registerSale.api.js').bind(this),
        updateSale: require('./classes/Sale/apis/updateSale.api.js').default.bind(this) || require('./classes/Sale/apis/updateSale.api.js').bind(this),
        updateSaleStage: require('./classes/Sale/apis/updateSaleStage.api.js').default.bind(this) || require('./classes/Sale/apis/updateSaleStage.api.js').bind(this),
        updateProductCount: require('./classes/Sale/apis/updateProductCount.api.js').default.bind(this) || require('./classes/Sale/apis/updateProductCount.api.js').bind(this),
        getSalesList: require('./classes/Sale/apis/getSalesList.api.js').default.bind(this) || require('./classes/Sale/apis/getSalesList.api.js').bind(this),
        getSaleByIds: require('./classes/Sale/apis/getSaleByIds.api.js').default.bind(this) || require('./classes/Sale/apis/getSaleByIds.api.js').bind(this),
        addFilesToSale: require('./classes/Sale/apis/addFilesToSale.api.js').default.bind(this) || require('./classes/Sale/apis/addFilesToSale.api.js').bind(this),
        addSaleDevice: require('./classes/Sale/apis/addSaleDevice.api.js').default.bind(this) || require('./classes/Sale/apis/addSaleDevice.api.js').bind(this),
        updateSaleDevice: require('./classes/Sale/apis/updateSaleDevice.api.js').default.bind(this) || require('./classes/Sale/apis/updateSaleDevice.api.js').bind(this),
        updateRcBooks: require('./classes/Sale/apis/updateRcBooks.api.js').default.bind(this) || require('./classes/Sale/apis/updateRcBooks.api.js').bind(this),
        getSaleDevicesByIds: require('./classes/Sale/apis/getSaleDevicesByIds.api.js').default.bind(this) || require('./classes/Sale/apis/getSaleDevicesByIds.api.js').bind(this),
        attachIdevice: require('./classes/Sale/apis/attachIdevice.api.js').default.bind(this) || require('./classes/Sale/apis/attachIdevice.api.js').bind(this),
        detachIdevice: require('./classes/Sale/apis/detachIdevice.api.js').default.bind(this) || require('./classes/Sale/apis/detachIdevice.api.js').bind(this),
        saleProductStageUpdate: require('./classes/Sale/apis/saleProductStageUpdate.api.js').default.bind(this) || require('./classes/Sale/apis/saleProductStageUpdate.api.js').bind(this),
        editSale: require('./classes/Sale/apis/editSale.api.js').default.bind(this) || require('./classes/Sale/apis/editSale.api.js').bind(this),
        moveSaleProduct: require('./classes/Sale/apis/moveSaleProduct.api.js').default.bind(this) || require('./classes/Sale/apis/moveSaleProduct.api.js').bind(this),
        getAggregatedCounts: require('./classes/Sale/apis/getAggregatedCounts.api.js').default.bind(this) || require('./classes/Sale/apis/getAggregatedCounts.api.js').bind(this),
        requestMakeAndModel: require('./classes/Sale/apis/requestMakeAndModel.api.js').default.bind(this) || require('./classes/Sale/apis/requestMakeAndModel.api.js').bind(this),
        deleteProducts: require('./classes/Sale/apis/deleteProducts.api.js').default.bind(this) || require('./classes/Sale/apis/deleteProducts.api.js').bind(this),
        removeSaleProducts: require('./classes/Sale/apis/removeSaleProducts.api.js').default.bind(this) || require('./classes/Sale/apis/removeSaleProducts.api.js').bind(this),
        getPerformanceSummary: require('./classes/Sale/apis/getPerformanceSummary.api.js').default.bind(this) || require('./classes/Sale/apis/getPerformanceSummary.api.js').bind(this),
        exportSalesList: require('./classes/Sale/apis/exportSalesList.api.js').default.bind(this) || require('./classes/Sale/apis/exportSalesList.api.js').bind(this),
        exportPerformanceSummary: require('./classes/Sale/apis/exportPerformanceSummary.api.js').default.bind(this) || require('./classes/Sale/apis/exportPerformanceSummary.api.js').bind(this),
        updateSaleDeliveryLot: require('./classes/Sale/apis/updateSaleDeliveryLot.api.js').default.bind(this) || require('./classes/Sale/apis/updateSaleDeliveryLot.api.js').bind(this),
        getSaleDeliveryLotById: require('./classes/Sale/apis/getSaleDeliveryLotById.api.js').default.bind(this) || require('./classes/Sale/apis/getSaleDeliveryLotById.api.js').bind(this),
        getSaleDeliveryLots: require('./classes/Sale/apis/getSaleDeliveryLots.api.js').default.bind(this) || require('./classes/Sale/apis/getSaleDeliveryLots.api.js').bind(this),
        getCartonById: require('./classes/Sale/apis/getCartonById.api.js').default.bind(this) || require('./classes/Sale/apis/getCartonById.api.js').bind(this),
        getCartonsList: require('./classes/Sale/apis/getCartonsList.api.js').default.bind(this) || require('./classes/Sale/apis/getCartonsList.api.js').bind(this),
        registerSaleDeliveryLot: require('./classes/Sale/apis/registerSaleDeliveryLot.api.js').default.bind(this) || require('./classes/Sale/apis/registerSaleDeliveryLot.api.js').bind(this),
        addIdeviceToSaleMultiple: require('./classes/Sale/apis/addIdeviceToSaleMultiple.api.js').default.bind(this) || require('./classes/Sale/apis/addIdeviceToSaleMultiple.api.js').bind(this),
}
this.Meta= {
        getAlgoTypes: require('./classes/Meta/apis/getAlgoTypes.api.js').default.bind(this) || require('./classes/Meta/apis/getAlgoTypes.api.js').bind(this),
        getSpecifications: require('./classes/Meta/apis/getSpecifications.api.js').default.bind(this) || require('./classes/Meta/apis/getSpecifications.api.js').bind(this),
        getAccessoryList: require('./classes/Meta/apis/getAccessoryList.api.js').default.bind(this) || require('./classes/Meta/apis/getAccessoryList.api.js').bind(this),
        getManufacturerList: require('./classes/Meta/apis/getManufacturerList.api.js').default.bind(this) || require('./classes/Meta/apis/getManufacturerList.api.js').bind(this),
        getProductsList: require('./classes/Meta/apis/getProductsList.api.js').default.bind(this) || require('./classes/Meta/apis/getProductsList.api.js').bind(this),
        getOperationsType: require('./classes/Meta/apis/getOperationsType.api.js').default.bind(this) || require('./classes/Meta/apis/getOperationsType.api.js').bind(this),
        updateRates: require('./classes/Meta/apis/updateRates.api.js').default.bind(this) || require('./classes/Meta/apis/updateRates.api.js').bind(this),
        getSimList: require('./classes/Meta/apis/getSimList.api.js').default.bind(this) || require('./classes/Meta/apis/getSimList.api.js').bind(this),
        getPaymentPlans: require('./classes/Meta/apis/getPaymentPlans.api.js').default.bind(this) || require('./classes/Meta/apis/getPaymentPlans.api.js').bind(this),
}
this.Note= {
        createNote: require('./classes/Note/apis/createNote.api.js').default.bind(this) || require('./classes/Note/apis/createNote.api.js').bind(this),
        getNotes: require('./classes/Note/apis/getNotes.api.js').default.bind(this) || require('./classes/Note/apis/getNotes.api.js').bind(this),
        updateNote: require('./classes/Note/apis/updateNote.api.js').default.bind(this) || require('./classes/Note/apis/updateNote.api.js').bind(this),
}
this.AlertLog= {
        getAlertLogs: require('./classes/AlertLog/apis/getAlertLogs.api.js').default.bind(this) || require('./classes/AlertLog/apis/getAlertLogs.api.js').bind(this),
        getAlertCounts: require('./classes/AlertLog/apis/getAlertCounts.api.js').default.bind(this) || require('./classes/AlertLog/apis/getAlertCounts.api.js').bind(this),
}
this.Idevice= {
        getIdeviceList: require('./classes/Idevice/apis/getIdeviceList.api.js').default.bind(this) || require('./classes/Idevice/apis/getIdeviceList.api.js').bind(this),
        getHardwareCodeList: require('./classes/Idevice/apis/getHardwareCodeList.api.js').default.bind(this) || require('./classes/Idevice/apis/getHardwareCodeList.api.js').bind(this),
        getDeviceModelList: require('./classes/Idevice/apis/getDeviceModelList.api.js').default.bind(this) || require('./classes/Idevice/apis/getDeviceModelList.api.js').bind(this),
        getHardwareComponentCategories: require('./classes/Idevice/apis/getHardwareComponentCategories.api.js').default.bind(this) || require('./classes/Idevice/apis/getHardwareComponentCategories.api.js').bind(this),
        registerDeviceModel: require('./classes/Idevice/apis/registerDeviceModel.api.js').default.bind(this) || require('./classes/Idevice/apis/registerDeviceModel.api.js').bind(this),
        registerDeviceHardwareConfig: require('./classes/Idevice/apis/registerDeviceHardwareConfig.api.js').default.bind(this) || require('./classes/Idevice/apis/registerDeviceHardwareConfig.api.js').bind(this),
        registerHardwareCategory: require('./classes/Idevice/apis/registerHardwareCategory.api.js').default.bind(this) || require('./classes/Idevice/apis/registerHardwareCategory.api.js').bind(this),
        registerNewManufacturingBatch: require('./classes/Idevice/apis/registerNewManufacturingBatch.api.js').default.bind(this) || require('./classes/Idevice/apis/registerNewManufacturingBatch.api.js').bind(this),
        getHardwareConfigs: require('./classes/Idevice/apis/getHardwareConfigs.api.js').default.bind(this) || require('./classes/Idevice/apis/getHardwareConfigs.api.js').bind(this),
        addDeviceLot: require('./classes/Idevice/apis/addDeviceLot.api.js').default.bind(this) || require('./classes/Idevice/apis/addDeviceLot.api.js').bind(this),
        getBatchList: require('./classes/Idevice/apis/getBatchList.api.js').default.bind(this) || require('./classes/Idevice/apis/getBatchList.api.js').bind(this),
        checkIdeviceModelAvailability: require('./classes/Idevice/apis/checkIdeviceModelAvailability.api.js').default.bind(this) || require('./classes/Idevice/apis/checkIdeviceModelAvailability.api.js').bind(this),
        reviseHardwareConfig: require('./classes/Idevice/apis/reviseHardwareConfig.api.js').default.bind(this) || require('./classes/Idevice/apis/reviseHardwareConfig.api.js').bind(this),
        checkIdeviceHwConfigAvailability: require('./classes/Idevice/apis/checkIdeviceHwConfigAvailability.api.js').default.bind(this) || require('./classes/Idevice/apis/checkIdeviceHwConfigAvailability.api.js').bind(this),
        getAvailabilityCount: require('./classes/Idevice/apis/getAvailabilityCount.api.js').default.bind(this) || require('./classes/Idevice/apis/getAvailabilityCount.api.js').bind(this),
        attachAuxBoard: require('./classes/Idevice/apis/attachAuxBoard.api.js').default.bind(this) || require('./classes/Idevice/apis/attachAuxBoard.api.js').bind(this),
        detachAuxBoard: require('./classes/Idevice/apis/detachAuxBoard.api.js').default.bind(this) || require('./classes/Idevice/apis/detachAuxBoard.api.js').bind(this),
        attachMultiSim: require('./classes/Idevice/apis/attachMultiSim.api.js').default.bind(this) || require('./classes/Idevice/apis/attachMultiSim.api.js').bind(this),
        detachSim: require('./classes/Idevice/apis/detachSim.api.js').default.bind(this) || require('./classes/Idevice/apis/detachSim.api.js').bind(this),
        getHardwareConfigById: require('./classes/Idevice/apis/getHardwareConfigById.api.js').default.bind(this) || require('./classes/Idevice/apis/getHardwareConfigById.api.js').bind(this),
        checkIfComponentCategoryAvailable: require('./classes/Idevice/apis/checkIfComponentCategoryAvailable.api.js').default.bind(this) || require('./classes/Idevice/apis/checkIfComponentCategoryAvailable.api.js').bind(this),
        getCodeList: require('./classes/Idevice/apis/getCodeList.api.js').default.bind(this) || require('./classes/Idevice/apis/getCodeList.api.js').bind(this),
        createNewFirmwareEntry: require('./classes/Idevice/apis/createNewFirmwareEntry.api.js').default.bind(this) || require('./classes/Idevice/apis/createNewFirmwareEntry.api.js').bind(this),
        updateFirmwareEntry: require('./classes/Idevice/apis/updateFirmwareEntry.api.js').default.bind(this) || require('./classes/Idevice/apis/updateFirmwareEntry.api.js').bind(this),
        uploadCodeFile: require('./classes/Idevice/apis/uploadCodeFile.api.js').default.bind(this) || require('./classes/Idevice/apis/uploadCodeFile.api.js').bind(this),
        markForUpgrade: require('./classes/Idevice/apis/markForUpgrade.api.js').default.bind(this) || require('./classes/Idevice/apis/markForUpgrade.api.js').bind(this),
        getSupportedHwFirmware: require('./classes/Idevice/apis/getSupportedHwFirmware.api.js').default.bind(this) || require('./classes/Idevice/apis/getSupportedHwFirmware.api.js').bind(this),
        getFirmwareById: require('./classes/Idevice/apis/getFirmwareById.api.js').default.bind(this) || require('./classes/Idevice/apis/getFirmwareById.api.js').bind(this),
        getDeviceAvailabiltyCount: require('./classes/Idevice/apis/getDeviceAvailabiltyCount.api.js').default.bind(this) || require('./classes/Idevice/apis/getDeviceAvailabiltyCount.api.js').bind(this),
        submitReturnReplaceDevice: require('./classes/Idevice/apis/submitReturnReplaceDevice.api.js').default.bind(this) || require('./classes/Idevice/apis/submitReturnReplaceDevice.api.js').bind(this),
        detachIdevice: require('./classes/Idevice/apis/detachIdevice.api.js').default.bind(this) || require('./classes/Idevice/apis/detachIdevice.api.js').bind(this),
        attachIdevice: require('./classes/Idevice/apis/attachIdevice.api.js').default.bind(this) || require('./classes/Idevice/apis/attachIdevice.api.js').bind(this),
}
this.Spec= {
        attachSpec: require('./classes/Spec/apis/attachSpec.api.js').default.bind(this) || require('./classes/Spec/apis/attachSpec.api.js').bind(this),
        detachSpec: require('./classes/Spec/apis/detachSpec.api.js').default.bind(this) || require('./classes/Spec/apis/detachSpec.api.js').bind(this),
        requestVersionInSpec: require('./classes/Spec/apis/requestVersionInSpec.api.js').default.bind(this) || require('./classes/Spec/apis/requestVersionInSpec.api.js').bind(this),
        listSpec: require('./classes/Spec/apis/listSpec.api.js').default.bind(this) || require('./classes/Spec/apis/listSpec.api.js').bind(this),
}
this.Inventory= {
        registerNewSupplier: require('./classes/Inventory/apis/registerNewSupplier.api.js').default.bind(this) || require('./classes/Inventory/apis/registerNewSupplier.api.js').bind(this),
        updateSupplierInfo: require('./classes/Inventory/apis/updateSupplierInfo.api.js').default.bind(this) || require('./classes/Inventory/apis/updateSupplierInfo.api.js').bind(this),
        getSupplierById: require('./classes/Inventory/apis/getSupplierById.api.js').default.bind(this) || require('./classes/Inventory/apis/getSupplierById.api.js').bind(this),
        getSupplierList: require('./classes/Inventory/apis/getSupplierList.api.js').default.bind(this) || require('./classes/Inventory/apis/getSupplierList.api.js').bind(this),
        getComponentById: require('./classes/Inventory/apis/getComponentById.api.js').default.bind(this) || require('./classes/Inventory/apis/getComponentById.api.js').bind(this),
        getComponentList: require('./classes/Inventory/apis/getComponentList.api.js').default.bind(this) || require('./classes/Inventory/apis/getComponentList.api.js').bind(this),
        registerNewComponent: require('./classes/Inventory/apis/registerNewComponent.api.js').default.bind(this) || require('./classes/Inventory/apis/registerNewComponent.api.js').bind(this),
        updateComponent: require('./classes/Inventory/apis/updateComponent.api.js').default.bind(this) || require('./classes/Inventory/apis/updateComponent.api.js').bind(this),
        updateComponentStock: require('./classes/Inventory/apis/updateComponentStock.api.js').default.bind(this) || require('./classes/Inventory/apis/updateComponentStock.api.js').bind(this),
        getCurrencyOptions: require('./classes/Inventory/apis/getCurrencyOptions.api.js').default.bind(this) || require('./classes/Inventory/apis/getCurrencyOptions.api.js').bind(this),
        getSchemaByModelName: require('./classes/Inventory/apis/getSchemaByModelName.api.js').default.bind(this) || require('./classes/Inventory/apis/getSchemaByModelName.api.js').bind(this),
        registerNewSchema: require('./classes/Inventory/apis/registerNewSchema.api.js').default.bind(this) || require('./classes/Inventory/apis/registerNewSchema.api.js').bind(this),
}
this.CustomerDetail= {
        addCustomerDetail: require('./classes/CustomerDetail/apis/addCustomerDetail.api.js').default.bind(this) || require('./classes/CustomerDetail/apis/addCustomerDetail.api.js').bind(this),
}
this.Driver= {
        getDriverByIds: require('./classes/Driver/apis/getDriverByIds.api.js').default.bind(this) || require('./classes/Driver/apis/getDriverByIds.api.js').bind(this),
}
this.Sim= {
        listSim: require('./classes/Sim/apis/listSim.api.js').default.bind(this) || require('./classes/Sim/apis/listSim.api.js').bind(this),
        addSim: require('./classes/Sim/apis/addSim.api.js').default.bind(this) || require('./classes/Sim/apis/addSim.api.js').bind(this),
        updateSimSubscription: require('./classes/Sim/apis/updateSimSubscription.api.js').default.bind(this) || require('./classes/Sim/apis/updateSimSubscription.api.js').bind(this),
}

    }
 	
    setConfig(config={}) {
        if(!config)
            return;
        if(config.storage)
            this.storage = config.storage;
        if(config.baseUrl)
            this.baseUrl = config.baseUrl;
        if(config.accountId)
            this.accountId = config.accountId;
        if(config.token)
            this.token = config.token;
        else {
            this.storage.getItem("UserToken").then((token)=>{
                this.token = token;
            });
        }
    }

    async getCurrentUser() {
        var user = await this.storage.getItem("User");
        if(user)
            return JSON.parse(user);
        return null;
    }
    
}
