import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function (options = {}) {
    var { token, accountId, imei, getOngoing, baseUrl, reportType } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "eol/report/get_by_imei?";
        if (imei) url += "imei=" + imei;
        else throw new Error("Imei not provided");
        if (reportType) url += "&report_type=" + reportType;
        if (getOngoing) url += "&ongoing=true";
        var results = await get(url, {
            token: config.token,
            accountId: config.accountId,
        });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
