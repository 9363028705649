import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        deployed,
        statusReasons = [],
        pnum,
        psize,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/getIdeviceAvailabilityCount?`;
        if(deployed)
            url += '&deployed=' + deployed;
        if(statusReasons.length)
            url +=  "&availability_status_reasons=" + statusReasons.join(",");
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}