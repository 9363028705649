if(!fetch) require('isomorphic-fetch');
export default async (url,{token,accountId,body}) => {
    if(token)
        url += "&token="+token;
    if(accountId)
        url += "&acc_id="+accountId;
    var response = await fetch(url,{
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
    var results = await response.json();
    return results;
}