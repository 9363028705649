/***
 * Update existing account
 * @param {Object} options Parameters to updare account
 * @param {Object} options.body Body for post call
 * @param {Boolean} options.accountId Account Id
 * @param {String} options.body.display_name Display name of account
 * @param {String} options.body.name Account Name
 * @param {String} options.body.type Account Type
 * @param {String} options.body.stage Account Stage
 * @param {Boolean} options.body.is_enabled Is account enabled
 */
import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        body
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl+`account/${config.accountId}/updateV2?`;
        var results = await post(url, { body, token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
};