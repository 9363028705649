import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
import isSuccessful from '../../../helpers/isSuccessful';
export default async function (options = {}) {
    var {
        body,
        baseUrl,
        doLogin
    } = options;
    try {
        if (!body) {
            throw new Error('No data provided');
        }
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + "v2/user/create_child_user?";
        if (doLogin)
            url += '&doLogin=true';
        var results = await post(url, { body, token: config.token });
        if (!isSuccessful(results))
            throw results;
        if (this && this.storage && results.user && results.user.user) {
            await Promise.all([
                this.storage.setItem("User", JSON.stringify(results.user.user)),
                this.storage.setItem("UserToken", results.user.token),
                this.storage.setItem("UserTokenDate", JSON.stringify(new Date().getTime())),
            ])
            var configObj = {
                token: results.token
            };
            if (results.user && results.user.user && !results.user.user.is_intangles_user && results.user.user.accountId)
                configObj.accountId = results.user.user.accountId;
            if (this && this.setConfig)
                this.setConfig(configObj);
        }
        return results;
    }
    catch (e) {
        throw e;
    }
};