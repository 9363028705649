import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
import getAccountsApi from './getAccounts.api';
export default async function (options = {}) {
    var { 
        token, baseUrl, accountId,
        noCache
    } = options;
    try {
        var config = fetchConfig.bind(this,options,true)();
        if(noCache) {
            var results = await getAccountsApi({ baseUrl: config.baseUrl, token: config.token, ...options, accIds: [ accountId ] });
            if(!results || !results.status || results.status.code != 200)
                throw results;
            var account = results.accounts && results.accounts.length ?
                results.accounts[0]: {};
            return {
                paging: results.paging,
                status: results.status,
                result: account
            };
        } 
        var url = config.baseUrl + `accountV2/${config.accountId}?`;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if(!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}