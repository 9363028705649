import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var { 
        token, baseUrl, accountId, vehicleId,
        startTime, endTime, alertLog, callback,
        noTotal = true, psize, pnum = 1,
    } = options;
    try {
        if(!vehicleId)
            throw new Error("Please provide vehicle id");
        var config = fetchConfig.bind(this, options, true)();
        var url = config.baseUrl + `vehicle/${vehicleId}/history/${startTime}/${endTime}?`;
        if(alertLog)
            url += "&alertlog=true";
        if(callback)
            url += "&callback=true";
        if(noTotal)
            url += "&no_total=true";
        if (!pnum)
            pnum = 1;
        url += '&pnum=' + pnum;
        if (psize)
            url += '&psize=' + psize;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        return results;
    }
    catch (e) {
        throw e;
    }
}