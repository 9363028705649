import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, psize = 50, pnum = 1,
        baseUrl, accountFilter, productIds,
        imeis, saleTag, salesPerson, getUserInfo,
        stage, currency, deploymentType, time_gt,
        startTime, time_lt, endTime, accountInfo,
        accountType, billingType, period, sorting,
        saleType, certTypes, replacementOrder
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "sale/list?pnum=" + pnum + "&psize=" + psize;

        imeis && imeis.length ? url += `&imeis=${options.imeis.join(",")}` : null;
        saleTag ? url = url + "&tag=" + saleTag : null;
        salesPerson ? url = url + "&psales_person=" + salesPerson : null;
        productIds && productIds.length ? url = url + "&product_id=" + productIds.join(",") : null;
        accountFilter ? url = url + "&account_id=" + accountFilter : null;
        if (stage) {
            if (Array.isArray(stage)) {
                url += '&stage=' + stage.join(',');
            } else
                url += '&stage=' + stage
        }
        if (certTypes) {
            if (Array.isArray(certTypes)) {
                url += '&cert_types=' + certTypes.join(',');
            } else
                url += '&cert_types=' + certTypes
        }
        if (saleType) {
            if (Array.isArray(saleType)) {
                url += '&sale_type=' + saleType.join(',');
            } else
                url += '&sale_type=' + saleType
        }
        replacementOrder ? url += "&replacement_order=" + replacementOrder : null;
        currency ? url = url + "&currency=" + currency : null;
        deploymentType ? url = url + "&deployment_type=" + deploymentType : null;
        startTime ? url = url + "&time_gte=" + startTime : null;
        time_gt ? url = url + "&time_gt=" + time_gt : null;
        time_lt ? url = url + "&time_lt=" + time_lt : null;
        endTime ? url = url + "&time_lte=" + endTime : null;
        accountType ? url = url + "&account_type=" + accountType : null;
        billingType ? url = url + "&billing_type=" + billingType : null;
        period ? url = url + "&period=" + period : null;
        accountInfo ? url += "&acc_info=true" : null;
        if (getUserInfo)
            url += '&get_user_info=true';

        if (sorting) {
            var sort = '';
            for (var i = 0; i < sorting.length; i++) {
                var sortType = sorting[i].isasc ? 'isasc' : 'desc';
                sort && sort !== "" ? sort += "," : null;
                sort += sorting[i].property + ' ' + sortType;
            }
            if (sort && sort !== '') {
                url += "&sort=" + sort;
            }
        }
        if (options) {
            if (options.proj && options.proj.length) {
                url += `&proj=${options.proj.join(",")}`;
            }
            if (options.tags && options.tags.length) {
                url += `&tags=${options.tags.join(",")}`;
            }
            if (options.hasOwnProperty("payment_completed")) {
                url += `&payment_completed=${options.paymentCompleted}`;
            }
        }

        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}