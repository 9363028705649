

import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize,
        pnum,
        specs,
        manufacturer,
        account_id,
        query,
        model
    } = options;
    try {
        console.log('options',options);
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + "sim/list?";
        psize ? url += `&psize=${psize}` : null;
        pnum ? url += `&pnum=${pnum}` : null;
        account_id ? url += `&account_id=${account_id}` : null;
        query ? url += `&query=${query}` : null;

        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};