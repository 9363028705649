import get from '../../../helpers/get';
export default async function (options = {}) {
    var {
        token,
        baseUrl
    } = options;
    try{
        if(!token)
            throw new Error("Please provide correct token");
        var config = {
            baseUrl: baseUrl || ( (this && this.baseUrl) ? this.baseUrl : 'https://apis.intangles.com/')
        }
        var url = config.baseUrl+"v2/user/getbytoken/"+token;
        var results = await get(url);
        if(!results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
}