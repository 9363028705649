import get from "../../../helpers/get";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function(options = {}) {
    var {
        noThrow,
        pnum,
        psize,
        fields,
        category,
        specification,
        intanglesPartCode,
        supplierPartCode,
        compClass,
        query,
        make,
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `/inventory/hwcomponent/list?`;
        
        if (!pnum) pnum = 1;
        if (!psize) psize = 20;
        if (category) url = url + "&category=" + category;
        if (fields) url = url + "&fields=" + fields;
        if (specification) url = url + "&specification=" + specification;
        if(intanglesPartCode) url = url + "&intangles_part_code=" + intanglesPartCode;
        if(supplierPartCode) url = url + "&supplier_part_code=" + supplierPartCode;
        if(compClass) url = url + "&class=" + compClass;
        if(query) url = url + "&query=" + query;
        if(make) url = url + "&make=" + make;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow) return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
