import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token,
        hwFrequency,
        subscriptionFrequency,
        type,
        name,
        query,
        baseUrl,
        accountIds = [],
        accountFields = [],
        productId
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        var url = config.baseUrl + 'finance/payment_plan?';
        if (hwFrequency) url += '&hw_frequency=' + hwFrequency;
        if (subscriptionFrequency)
            url += '&subscription_frequency=' + subscriptionFrequency;
        if (type) url += '&type=' + type;
        if (name) url += '&name=' + name;
        if (query) url += '&query=' + query;
        if (accountFields.length)
            url += '&account_fields=' + accountFields.join(',');
        if (accountIds && accountIds.length)
            url += '&account_ids=' + accountIds.join(',');
        if (productId) url += '&product_id=' + productId;
        let results = await get(url, {
            token: config.token,
            accountId: config.accountId,
        });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}