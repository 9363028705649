import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        saleId,
        stage,
        accountId
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + `saleproduct/${saleId}/stage/${stage}/update?`;
        var results = await post(url, { token: config.token, accountId: accountId ? accountId : config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};