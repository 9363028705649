import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        startTime, endTime, notificationTypes, subtypes, types, vehicles,
        pnum, psize, users, noThrow, accountFilter, not_id, notificationId,
        pushOpened, push_opened
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `notification/get?`;
        if (accountFilter)
            url += 'account_id=' + accountFilter;
        if (users && !Array.isArray(users))
            users = [users];
        if (notificationTypes && !Array.isArray(notificationTypes))
            notificationTypes = [notificationTypes];
        if (vehicles && !Array.isArray(vehicles))
            vehicles = [vehicles];
        if (types && !Array.isArray(types))
            types = [types];
        if (subtypes && !Array.isArray(subtypes))
            subtypes = [subtypes];
        if (!pnum)
            pnum = 1;
        url += '&pnum=' + pnum;
        if (psize)
            url += '&psize=' + psize;
        if (users && users.length)
            url += '&user_id=' + users.join(',');
        if (notificationTypes && notificationTypes.length)
            url += '&notification_type=' + notificationTypes.join(',');
        if (subtypes && subtypes.length)
            url += '&subtype=' + subtypes.join(',');
        if (types && types.length)
            url += '&type=' + types.join(',');
        if (vehicles && vehicles.length)
            url += '&vehicle_id=' + vehicles.join(',');
        if (endTime)
            url += '&end_time=' + endTime;
        if (startTime)
            url += '&start_time=' + startTime;
        if (pushOpened || push_opened)
            url += '&push_opened=true';
        if (not_id)
            url += '&not_id=' + not_id;
        if (notificationId)
            url += '&not_id=' + notificationId;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}