export default function (options = {}, accountIdRequired) {
    var {
        token, baseUrl, accountId
    } = options;
    var config = {
        baseUrl: baseUrl || ((this && this.baseUrl) ? this.baseUrl : 'https://apis.intangles.com/'),
        token: token || ((this && this.token) ? this.token : ''),
        accountId: accountId || ((this && this.accountId) ? this.accountId : ''),
    };
    if (config.baseUrl.substr(-1) != '/')
        config.baseUrl += "/";
    if (accountIdRequired && !config.accountId)
        throw new Error("Please provide account id");
    return config;
}