import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize = 50, pnum = 1,
        type, stage, category,
        isIntangles, tags
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "accessory/list?pnum=" + pnum + "&psize=" + psize;
        if(type)
            url += `&type=${type}`;
        if(stage)
            url += `&stage=${stage}`;
        if(category)
            url += `&category=${category}`;
        if(isIntangles)
            url += `&is_intangles=${isIntangles}`;
        if(tags && Array.isArray(tags))
            url += `&tags=${tags.join(',')}`;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}