import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
/**
 * @param  {} options={}
 * @param  {} startTime
 * @param  {} endTime
 * @param  {} salesPerson
 */
export default async function (options = {}) {
    var { 
        startTime, endTime, salesPerson, noThrow,
        deviceCount,
        createCount
    } = options;
    try {
        var config = fetchConfig.bind(this,options,false)();
        var url = config.baseUrl + `v2/sale/getaggregatedcounts?`;
        if(startTime)
            url += '&start_time=' + startTime;
        if(endTime)
            url += '&end_time=' + endTime;
        if(salesPerson)
            url += '&sales_person=' + salesPerson;
        if(deviceCount)
            url += '&device_count=true';
        if(createCount)
            url += '&create_count=true';
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if(noThrow)
            return results;
        if(!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}