import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `account/getOperationsType?`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}