import getVehicles from './getVehicleList.api';
export default async function (options = {}) {
    try {
        var data = [];
        options.pnum = 1;
        options.psize = 200;
        do {
            var res = await getVehicles.bind(this)(options);
            if(!res || !res.status || res.status.code != 200)
                throw res;
            var total = res.paging.total;
            data = data.concat(res.v);
            options.pnum++;
            if (total <= data.length) {
                return {
                    total,
                    v: data
                };
            }
        }
        while (total > data.length);
    }
    catch(e) {
        throw e
    }
}