/**
 * @flow
 */
import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';

export default async function(options = {}) {
    var { token, driverIds, getVehicleInfo } = options;

    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + 'driver/getbyids?';

        if (driverIds && Array.isArray(driverIds) && driverIds.length) {
            url += `&ids=${driverIds.join(',')}`;
        }
        getVehicleInfo ? (url += '&get_vehicle_info=true') : null;

        let results = {};

        results = await get(url, {
            token: config.token,
        });

        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
