import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
/**
 * @param  {} options={}
 * @param  {} options.noThrow
 * @param  {} options.query
 * @param  {} options.model
 * @param  {} options.pnum
 * @param  {} options.psize
 * @param  {} options.manufacturer
 * @param  {} options.specIds
 * @param  {} options.useTextSearch
 */
export default async function (options = {}) {
    var {
        noThrow,
        query,
        model,
        pnum,
        psize,
        manufacturer,
        specIds,
        useTextSearch
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `specs/listV2?`;
        if (query && query != '*')
            url += '&query=' + query;
        if (model && model != '*')
            url += '&model=' + model;
        if (manufacturer)
            url += '&manufacturer=' + manufacturer;
        if (useTextSearch && useTextSearch != '*')
            url += '&use_textsearch=' + useTextSearch;  
        if (specIds) {
            if(!Array.isArray(specIds))
                throw new Error("specIds should be an array");
            url += '&specs=' + specIds.join(',');
        }
        pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}