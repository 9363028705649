import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var { 
        token, baseUrl, accountId, vehicleId
    } = options;
    try {
        if(!vehicleId)
            throw new Error("Please provide vehicle id");
        var config = fetchConfig.bind(this,options,true)();
        var url = config.baseUrl + `vehicle/${vehicleId}?`;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if(!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}