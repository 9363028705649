import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        noThrow,
        pnum = 1,
        psize = 50,
        name,
        country,
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `manufacturers/listV2?`;
        name ? url += '&name=' + name : null;
        country ? url += '&country=' + country : null;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;
        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}