import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token, psize = 50, pnum = 1,
        status, query, stage,
        baseUrl, accountId, accIds = [],
        typeFilter, getRates,
        zohoinfo
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        var url = config.baseUrl + "account/listV2?";
        if (psize)
            url += "&psize=" + psize;
        if (accIds)
            url += "&acc_ids=" + accIds.join(',');
        if (pnum)
            url += "&pnum=" + pnum;
        if (status)
            url += "&status=" + status;
        else
            url += "&status=*";
        if (query)
            url += "&query=" + query;
        else
            url += "&query=*";
        if (stage && stage != 'ALL')
            url += "&stage=" + stage;
        if (typeFilter)
            url += "&type=" + typeFilter;
        if (getRates)
            url += "&getrates=true";
        if (zohoinfo) url += "&zohoinfo=" + zohoinfo;
        let results = await get(url, { token: config.token });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }

}
