import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        specId,
        vehicleId
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl+ "vehicle/" + vehicleId + "/attachspecV2/" + specId  + "?";
        var results = await post(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
};