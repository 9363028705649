import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        name,
        modelName,
        query,is_aux,
        pnum,
        psize,
        noThrow
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + `idevice/model/list?`;
        if(query)
            url += '&query=' + query;
        if(name && !modelName)
            url += '&name=' + name;
        if(modelName && !name   )
            url += '&name=' + modelName;
        is_aux ? url += '&is_aux=' + is_aux : null;
        if (!pnum)
            pnum = 1;
        if (!psize)
            psize = 20;

        url += `&pnum=${pnum}&psize=${psize}`;
        var results = await get(url, { token: config.token });
        if (noThrow)
            return results;
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}