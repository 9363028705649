import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize = 50, pnum = 1,
        query, intanglesUsers, isEnabled,
        isIntanglesUser, isAccountAdmin, noTotal = true
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "v2/user/list?pnum=" + pnum + "&psize=" + psize;
        if (query)
            url += "&query=" + query;
        if (intanglesUsers)
            url += "&intangles_users=" + intanglesUsers;
        if (isIntanglesUser)
            url += "&is_intangles_user=" + isIntanglesUser;
        if (isAccountAdmin)
            url += "&is_account_admin=" + isAccountAdmin;
        if (isEnabled)
            url += "&is_enabled=" + isEnabled;
        // var no_account_id;
        // if (options && options.no_account_id)
        //     no_account_id = true;
        if(noTotal)
            url = url + "&no_total=true";
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}