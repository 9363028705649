import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize = 50, pnum = 1,
        type, search
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "sim/list?pnum=" + pnum + "&psize=" + psize;
        if(type && type !== 'all')
            url += `&type=${type}`;
        if(search)
            url += `&search=${search}`;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}