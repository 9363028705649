import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        phoneNumber,
        phone,
        countryCode,
        baseUrl,
        appName
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var body = {
            phone_number: phoneNumber,
            app_name: appName
        }
        if(phone && countryCode) {
            body = {
                app_name: appName,
                phone,
                country_code: countryCode
            }
        }
        var url = config.baseUrl+'v2/user/verification/requestOtp?';
        var results = await post(url, { body, token: config.token });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
};