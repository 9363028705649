import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        token,
        accountId,
        codes = [], protocol = '',
        manufacturer = '',
        baseUrl,
        emissionStandard
    } = options;
    try {
        var config = fetchConfig.bind(this,options,true)();
        var url = config.baseUrl + "dtc/listV2?";
        var dtcsCodes = codes.join(',');
        var manu = manufacturer ? manufacturer : '';
        url += "codes="+dtcsCodes +"&man="+ manu+ "&protocol=" + protocol;
        if(emissionStandard) url +='&emission_standard=' + emissionStandard;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
}