import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        phone,
        countryCode,
        baseUrl,
        otp
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var body = {};
        if(phone && countryCode) {
            body = {
                phone,
                country_code: countryCode,
                otp
            }
        } else {
            throw new Error('Incorrect body parameter');
        }
        var url = config.baseUrl+'v2/user/loginwithotp?';
        var results = await post(url, { body, token: config.token });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        if (this && this.storage) {
            await Promise.all([
                this.storage.setItem("User", JSON.stringify(results.user)),
                this.storage.setItem("UserToken", results.token),
                this.storage.setItem("UserTokenDate", JSON.stringify(new Date().getTime())),
            ])
        }
        var configObj = {
            token: results.token
        };
        if(results.user && !results.user.is_intangles_user && results.accountId)
            configObj.accountId = results.accountId;
        if(this && this.setConfig)
            this.setConfig(configObj);
        return results;
    }
    catch(e){
        throw e;
    }
};