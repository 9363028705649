/***
 * Adds new customer detail
 * @param {Object} options Parameters to create new customer detail
 * @param {Object} options.body Body for post call
 * @param {String} options.body.name Name of the customer
 * @param {Number} options.body.phone_no Customer Phone no
 * @param {String} options.body.address Customer Address
 * @param {String} options.body.address_proof_no Customer address proof no
 * @param {String} options.body.identity_proof_no Customer identity proof no
 * @param {Boolean} options.body.files Customer other documents and files
 * @param {Object} options.body.address_proof Customer address proof
 * @param {Object} options.body.identity_proof Customer identity proof
 */
import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
//@flow
export default async function (options = {}) {
    var {
        body
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != '/')
            config.baseUrl += "/";
        var url = config.baseUrl + 'customer/detail/add?';
        var results = await post(url, { body, token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch (e) {
        throw e;
    }
};