import get from '../../../helpers/get';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        psize = 10, pnum = 1,
        name, type, category,
        addOn = [], status, tags,
        get_deprecated
    } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        var url = config.baseUrl + "product/list?pnum=" + pnum + "&psize=" + psize;
        type ? url += `&type=${type}` : null;
        name ? url += `&name=${name}` : null;
        category ? url += `&category=${category}` : null;
        status ? url += `&status=${status}` : null;        

        if (addOn && Array.isArray(addOn) && addOn.length) {
            url += `&add_on=${addOn.join(",")}`;
        }
        tags && Array.isArray(tags) ? url += `&tags=${tags.join(',')}` : null;
        options && options.hasOwnProperty('get_deprecated') ? url += `&get_deprecated=${options.get_deprecated}` : false;
        var results = await get(url, { token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    } catch (e) {
        throw e;
    }
}