import put from '../../../helpers/put';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        url,
        file
    } = options;
    try {
        var results = await put(url, file);
        // if (!results || !results.status || results.status.code != 200)
        //     throw results;
        return results;
    }
    catch(e){
        throw e;
    }
};