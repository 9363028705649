import post from "../../../helpers/post";
import fetchConfig from "../../../helpers/fetchConfig";
export default async function(options = {}) {
    var { body, saleId, updateMail = false, accountId } = options;
    try {
        var config = fetchConfig.bind(this, options)();
        if (config.baseUrl.substr(-1) != "/") config.baseUrl += "/";
        var url = config.baseUrl + `sale/${saleId}/edit?`;

        if (updateMail) url += "&update_mail=true";
        if (accountId) url += `&acc_id=${accountId}`;

        var results = await post(url, { body, token: config.token, accountId: config.accountId });
        if (!results || !results.status || results.status.code != 200) throw results;
        return results;
    } catch (e) {
        throw e;
    }
}
