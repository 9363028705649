import post from '../../../helpers/post';
import fetchConfig from '../../../helpers/fetchConfig';
export default async function (options = {}) {
    var {
        body={},
        baseUrl,
        noToken,
    } = options;
    try {
        var config = fetchConfig.bind(this,options)();
        var url = config.baseUrl+'v2/user/verification/verifyOtp?';
        if(noToken)
            url =  config.baseUrl+'v2/user/verification/verifyOtpWithoutToken?';
        var results = await post(url, { body, token: config.token });
        if (!results || !results.status || results.status.code != 200)
            throw results;
        return results;
    }
    catch(e){
        throw e;
    }
};